export function isRequestIOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

function getResizedImageBreakpoint(url, width, breakpoints) {
    for (let i = 0; i < breakpoints.length; i++) {
        let breakpoint = breakpoints[i];
        if (width <= breakpoint.maxWidth) {
            return breakpoint.width;
        }
    }

    // return max image size
    return breakpoints[breakpoints.length-1].width;
}

export function getResizedImageUrl(url, width, breakpoints, format) {
    let resizedWidth = getResizedImageBreakpoint(url, width, breakpoints);

    let resizedUrl = url + '?w=' + resizedWidth;
    if (format) {
        resizedUrl += '&fm=' + format;
    }
    return resizedUrl;
}

export function getImageSupport() {
    let promise = new Promise((resolve, reject) => {
        let avif = new Image();
        avif.onload = () => {
            resolve('avif');
        };
        avif.src = "data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A=";
        avif.onerror = () => {
            let webp = new Image();
            webp.onload = () => {
                resolve('webp');
            }
            webp.onerror = () => {
                reject();
            }
            webp.src = "data:image/webp;base64,UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==";
        }
    });
    return promise;
}

export function getSrcSet(url, sizes, format) {
    let srcSet = '';
    sizes.forEach(i => {
        let resizedUrl = url + '?w=' + i;
        if (format) {
            resizedUrl += '&fm=' + format;
        }

        if (srcSet) {
            srcSet += ', ';
        }
        srcSet += (resizedUrl + ' ' + i + 'w');
    });
    return srcSet;
}
