export const TileSpecials = [
    {
        lat: 7.5,
        lng: -15,
        settings: {
            skip: false,
            widthSpan: 2,
            heightSpan: 2,
            lat: -7.5,
            lng: 7.5,
            pillarTitle: true
        }
    },
    {
        lat: 7.5,
        lng: 0,
        settings: {
            skip: true
        }
    },
    {
        lat: -7.5,
        lng: -15,
        settings: {
            skip: true
        }
    },
    {
        lat: -7.5,
        lng: 0,
        settings: {
            skip: true
        }
    },

    {
        lat: 22.5,
        lng: -90,
        settings: {
            skip: false,
            widthSpan: 2,
            heightSpan: 2,
            lat: -7.5,
            lng: 7.5
        }
    },
    {
        lat: 22.5,
        lng: -75,
        settings: {
            skip: true
        }
    },
    {
        lat: 7.5,
        lng: -90,
        settings: {
            skip: true
        }
    },
    {
        lat: 7.5,
        lng: -75,
        settings: {
            skip: true
        }
    },

    {
        lat: 22.5,
        lng: 60,
        settings: {
            skip: false,
            widthSpan: 2,
            heightSpan: 2,
            lat: -7.5,
            lng: 7.5
        }
    },
    {
        lat: 22.5,
        lng: 75,
        settings: {
            skip: true
        }
    },
    {
        lat: 7.5,
        lng: 60,
        settings: {
            skip: true
        }
    },
    {
        lat: 7.5,
        lng: 75,
        settings: {
            skip: true
        }
    },

    {
        lat: 7.5,
        lng: 135,
        settings: {
            skip: false,
            widthSpan: 2,
            heightSpan: 2,
            lat: -7.5,
            lng: 7.5
        }
    },
    {
        lat: 7.5,
        lng: 150,
        settings: {
            skip: true
        }
    },
    {
        lat: -7.5,
        lng: 135,
        settings: {
            skip: true
        }
    },
    {
        lat: -7.5,
        lng: 150,
        settings: {
            skip: true
        }
    },

    // {
    //     lat: 52.5,
    //     lng: -165,
    //     settings: {
    //         skip: false,
    //         widthSpan: 2,
    //         heightSpan: 2,
    //         lat: 82.5,
    //         lng: -170
    //     }
    // },
    // {
    //     lat: 52.5,
    //     lng: -150,
    //     settings: {
    //         skip: true
    //     }
    // },
    // {
    //     lat: 37.5,
    //     lng: -165,
    //     settings: {
    //         skip: true
    //     }
    // },
    // {
    //     lat: 37.5,
    //     lng: -150,
    //     settings: {
    //         skip: true
    //     }
    // }

    // top left
    // {
    //     lat: 52.5,
    //     lng: -45,
    //     settings: {
    //         skip: false,
    //         widthSpan: 1,
    //         heightSpan: 1,
    //         lat: 52.5,
    //         lng: -45
    //     }
    // },
    // {
    //     lat: 52,
    //     lng: -30,
    //     settings: {
    //         skip: true
    //     }
    // },
    // {
    //     lat: 37.5,
    //     lng: -45,
    //     settings: {
    //         skip: true
    //     }
    // },
    // {
    //     lat: 37.5,
    //     lng: -30,
    //     settings: {
    //         skip: true
    //     }
    // }

    // {
    //     lat: 82.5,
    //     lng: -180,
    //     settings: {
    //         skip: false,
    //         widthSpan: 3,
    //         heightSpan: 1,
    //         lat: 72.5,
    //         lng: -150
    //     }
    // },
    // {
    //     lat: 82.5,
    //     lng: -165,
    //     settings: {
    //         skip: true
    //     }
    // },
    // {
    //     lat: 82.5,
    //     lng: -150,
    //     settings: {
    //         skip: true
    //     }
    // },
    // {
    //     lat: 82.5,
    //     lng: 30,
    //     settings: {
    //         skip: true
    //     }
    // },
];