import React, { Component } from 'react';
import './CharlieWatts.scss';

class CharlieWatts extends Component {
    render() {
        return (
            <img onClick={this.props.onClick} className="easter-egg--charlie-watts" src={process.env.PUBLIC_URL + "/assets/images/easter/charlie-watts/Drumsticks.png"} alt="Drumsticks" />
        );
    }
}

export default CharlieWatts;