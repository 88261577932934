import React, { Component } from 'react';
import TileDetails from '../TileDetails/TileDetails';
import LuckyButton from './LuckyButton';
import LanguageSwitcher from './LanguageSwitcher';
import SearchBox from './SearchBox';
import IntroMessage from '../intro-message/IntroMessage';
import './LewisGlobe.scss';
import Globe from 'react-globe.gl';
import * as THREE from 'three';
import gsap from 'gsap';
import { TileSpecials } from './TileSpecials';
import { getResizedImageUrl, getImageSupport } from '../common/scripts/utils';
import TourDeFrance from '../easter-eggs/TourDeFrance';
import CharlieWatts from '../easter-eggs/CharlieWatts';
import Hooverphonic from '../easter-eggs/Hooverphonic';
import SquidGame from '../easter-eggs/SquidGame';

import { ApolloClient, InMemoryCache, gql } from "@apollo/client";

const tilesQuery = gql`query($locale: String) {
    globeCollection(limit: 1, locale: $locale){
      items{
        title,
        tileDetailsHeader,
        tileDetailsFooter,
        luckyButtonText,
        languageSwitcherText,
        searchPlaceholder,
        searchNoResults,
        googleTrendsUrl,
        tilesCollection {
          items {
            title,
            thumbnail {
                    url,
              contentType
            },
            description {
              json
            },
            image {
              url,
              contentType
            },
            easterEgg,
            categoriesCollection {
              items {
                title
              }
            },
            embeddedVideo,
            mediaSource,
            video {
                url,
                contentType
            }
          }
        }
      }
    }
  }`;

const contentfulClient = new ApolloClient({
    uri: 'https://graphql.contentful.com/content/v1/spaces/j5s8wny6hp41/environments/master?access_token=TFGSJJhu28AOlFSBCl6CNVypKT-8X5MLrqBarY-FxoQ',
    cache: new InMemoryCache()
});

const TILE_MARGIN = 0.35; // degrees
const GRID_SIZE = [24, 12];
const defaultPillarIndex = 0; // causes
const reservedMaterials = 0;
const tileWidth = 360 / GRID_SIZE[0]; // 15
const tileHeight = 180 / GRID_SIZE[1]; // 15
const tileAspect = tileWidth / tileHeight;
const tileImageBreakpoints = [
    {
        width: 100,
        maxWidth: 320
    },
    {
        width: 150,
        maxWidth: 380
    },
    {
        width: 200,
        maxWidth: 480
    },
    {
        width: 250,
        maxWidth: 768
    },
    {
        width: 300,
        maxWidth: 1366
    },
    {
        width: 400,
        maxWidth: 1920
    }
];

const EasterEggs = {
    TourDeFrance: 'Tour de France',
    CharlieWatts: 'Charlie Watts',
    Hooverphonic: 'Hooverphonic',
    Covid: 'Covid-19',
    SquidGame: 'Squid Game'
}

const getFullSizeScale = (camera, target, size, factor = 1) => {
    const v = getCurrentViewport(camera, target, size);
    const { width, height } = v;
    const aspect = size.width / size.height;

    const adaptedHeight = height * (aspect > width / height ? v.width / width : v.height / height)
    const adaptedWidth = width * (aspect > width / height ? v.width / width : v.height / height)
    return [adaptedWidth * factor, adaptedHeight * factor, 1];
}

const distanceVector = ( v1, v2 ) => {
    var dx = v1.x - v2.x;
    var dy = v1.y - v2.y;
    var dz = v1.z - v2.z;
    return Math.sqrt( dx * dx + dy * dy + dz * dz );
}

const getCurrentViewport = (camera, target, size) => {
    const { width, height } = size
    const distance = distanceVector(camera.position, target.position);
    const fov = (camera.fov * Math.PI) / 180 // convert vertical fov to radians
    const h = 2 * Math.tan(fov / 2) * distance // visible height
    const w = h * (width / height)
    return { width: w, height: h, factor: width / w, distance };
}

const loader = new THREE.TextureLoader();
const texturePromises = [];
const backgroundImageUrl = process.env.PUBLIC_URL + "/assets/images/bg-google-star.jpg";
const covidBackgroundImageUrl = process.env.PUBLIC_URL + "/assets/images/easter/covid.jpg";
const localeSessionStorageKey = 'yearInSearch.locale';

class LewisGlobe extends Component {
    constructor(props) {
        super(props);

        this.isIos = false; // isRequestIOS();
        this.totalMaterials = 300;
        this.imageFormat = '';

        this.globeEl = React.createRef();
        this.world = null;

        this.materialResolutionDefaultSettings = this.getInitialMaterialResolutionSettings(500, 280);
        this.materialResolutionHeroSettings = this.getInitialMaterialResolutionSettings(400, 400);
        this.materialResolutionVimeoSettings = this.getInitialMaterialResolutionSettings(640, 360);

        this.state = {
            globeWidth: window.innerWidth,
            globeHeight: window.innerHeight,
            activePillarIndex: defaultPillarIndex,
            selectedPillarContent: null,
            tilesData: [],
            enteredTheMatrix: true,
            materialToggleActive: false,
            searchResults: [],
            introMessageLoaded: false,
            easterEgg: null,
            locale: window.sessionStorage.getItem(localeSessionStorageKey) || 'nl-BE',
            tileSettings: {},
            backgroundImageUrl: backgroundImageUrl
        };

        this.easterEggTimeout = null;

        getImageSupport().then((format) => {
            this.imageFormat = format;
        });
    }

    getInitialMaterialResolutionSettings(width, height) {
        let imageAspect = height / width;
        let settings = {
            materialResolutionZ: 0,
            materialResolutionW: 0
        }

        if(tileHeight / tileWidth > imageAspect) {
            settings.materialResolutionZ = (tileWidth / tileHeight) * imageAspect ;
            settings.materialResolutionW = 1;
        } else{
            settings.materialResolutionZ = 1;
            settings.materialResolutionW = (tileHeight / tileWidth) / imageAspect;
        }

        return settings;
    }

    generateTiles = () => {
        let tilesData = [];
        let pillarTileCount = 0;
        
        [...Array(GRID_SIZE[0]).keys()].forEach(lngIdx => {
            [...Array(GRID_SIZE[1]).keys()].forEach(latIdx => {
                let lat = -90 + (latIdx + 0.5) * tileHeight;
                let lng = -180 + lngIdx * tileWidth;

                let width = tileWidth - TILE_MARGIN;
                let height = tileHeight - TILE_MARGIN;

                let material = null;
                let isPillarTitle = false;

                let reservedTile = TileSpecials.find(i => i.lat === lat && i.lng === lng);
                if (reservedTile) {
                    if (reservedTile.settings.skip) {
                        return;
                    }

                    width = tileWidth * (reservedTile.settings.widthSpan) - TILE_MARGIN;
                    height = tileHeight * (reservedTile.settings.heightSpan) - TILE_MARGIN;
                    lat += reservedTile.settings.lat;
                    lng += reservedTile.settings.lng;

                    if (reservedTile.settings.pillarTitle) {
                        material = this.materials[this.materials.length - 1];
                        isPillarTitle = true;
                        pillarTileCount++;
                    }
                }

                // top and bottom tiles
                if (lat === 82.5 || lat === -82.5) {
                    material = this.materials[this.materials.length - 1];
                    isPillarTitle = true;
                    pillarTileCount++;
                } else if (material === null) {
                    let tileIndex = tilesData.length - pillarTileCount;
                    if (tileIndex >= 0 && tileIndex < (this.materials.length - reservedMaterials)) {
                        material = this.materials[tileIndex];
                    } else {
                        material = this.materials[Math.floor(Math.random() * (this.materials.length - reservedMaterials))];
                    }
                }

                tilesData.push({
                    lng: lng,
                    lat: lat,
                    material: material,
                    altitude: 0.01,
                    width: width,
                    height: height,
                    isPillarTitle: isPillarTitle,
                    title: material.pillarContent.title || material.pillarContent.type,
                    easterEgg: material.pillarContent.easterEgg
                })
            });
        });

        this.setState({
            tilesData: tilesData
        });

        this.createAutocompleteResults();

        setTimeout(() => {
            this.animateMaterials();
        }, 5000);
    }

    animateMaterials() {
        this.materials.forEach(material => {
            if (material.pillarContent.thumbnail.contentType.indexOf('image') === 0) {
                const opacity = 1;
                let opacityTemp = {
                    value: material.opacity
                }

                const randomDelay = Math.floor(Math.random() * 10);

                gsap.to(opacityTemp, {
                    value: opacity,
                    onUpdate: () => {
                        material.opacity = opacityTemp.value
                    },
                    delay: randomDelay,
                    duration: 1,
                    ease: "power1.inOut"
                });
            }
        });
    }

    getTileImageUrl(url) {
        return getResizedImageUrl(url, window.innerWidth, tileImageBreakpoints, this.imageFormat);
    }

    loadTextures = () => {
        this.state.tileSettings.content.forEach((item) => {
            if (item.thumbnail.contentType.indexOf('image') === 0 || this.isIos) {
                let promise = new Promise(resolve => {
                    let url = item.thumbnail.url;
                    item.texture = loader.load(this.getTileImageUrl(url), resolve);
                });
                texturePromises.push(promise);
            } else if (item.thumbnail.contentType.indexOf('video') === 0) {
                // dynamically create the video
                item.texture = this.loadVideo(item.thumbnail.url);
            } 
        });
    }

    setVideoSrc(src, video) {
        video.src = src;
        video.load();
        video.play();
    }

    loadVideo(src) {
        let video = document.createElement('video');
        video.muted = true;
        video.loop = true;
        video.crossOrigin = !src.startsWith('/');

        if (src.startsWith('https://player.vimeo.com/')) {
            // handle vimeo access control error - https://stackoverflow.com/q/67923435/1429384
            fetch(src, {
                method: 'HEAD'
            })
            .then(response => {
                this.setVideoSrc(response.url || src, video);
            }).catch(() => {
                this.setVideoSrc(src, video);
            });
        } else {
            this.setVideoSrc(src, video);
        }

        var texture = new THREE.VideoTexture( video );
        texture.minFilter = THREE.LinearFilter; 
        texture.magFilter = THREE.LinearFilter; 
        texture.format = THREE.RGBFormat;
        return texture;
    }

    // getResolutionSettings(pillar) {
    //     return pillar.thumbnail.type === 'video' ? this.materialResolutionVimeoSettings : this.materialResolutionDefaultSettings;
    // }

    createAutocompleteResults = () => {
        let searchResults = [];
        this.state.tileSettings.content.forEach(content => {
            searchResults.push({
                title: content.title
            });
        });

        this.setState({
            searchResults: searchResults
        });
    }

    createMaterials = () => {
        let materials = [];

        this.state.tileSettings.content.forEach((content) => {
            if (content.texture && materials.length < this.totalMaterials) {
                // insert in the array randomly so sections of the globe aren't all the same pillar
                let insertIndex = Math.floor(Math.random() * materials.length);
                let material = this.createBasicMaterial(content.texture, content, 0, false);
                materials.splice(insertIndex, 0, material);
            }
        });

        return materials;
    }

    updateTileTexture = (texture) => this.updateTexture(texture, tileAspect);

    updateTexture = (texture, aspect) => {
        texture.matrixAutoUpdate = false;

        var imageAspect = texture.image.width / texture.image.height;

        if ( aspect < imageAspect ) {
            texture.matrix.setUvTransform( 0, 0, aspect / imageAspect, 1, 0, 0.5, 0.5 );
        } else {
            texture.matrix.setUvTransform( 0, 0, 1, imageAspect / aspect, 0, 0.5, 0.5 );
        }
    }

    updateMaterials() {
        Promise.all(texturePromises).then(() => {
            // update aspect ratio
            this.materials.forEach(material => {
                if (material.type === 'MeshBasicMaterial' && material.pillarContent && material.pillarContent.thumbnail.type === 'image') {
                    this.updateTileTexture(material.map);
                    return;
                }

                if (!material.uniforms) {
                    return;
                }

                if (!material.uniforms.texture1.value) {
                    console.log('broken material', material.uniforms.pillars, material.uniforms.texture1);
                    return;
                }

                if (material.uniforms.pillars[0].thumbnail.type === 'video') {
                    return;
                }

                let imageAspect = material.uniforms.texture1.value.image.height / material.uniforms.texture1.value.image.width;
                let a1; let a2;

                if(tileHeight / tileWidth > imageAspect) {
                    a1 = (tileWidth / tileHeight) * imageAspect ;
                    a2 = 1;
                } else{
                    a1 = 1;
                    a2 = (tileHeight / tileWidth) / imageAspect;
                }
                material.uniforms.resolution.value = new THREE.Vector4(tileWidth, tileHeight, a1, a2);
            });
        });
    }

    createBasicMaterial = (texture, content, pillarIndex, isTitleTile) => {
        const isImage = content.thumbnail.contentType.indexOf('image') === 0;

        let material = new THREE.MeshBasicMaterial({
			map: texture,
            transparent: isImage,
            opacity: isImage ? 0.6 : 1
        });

        material.pillarContent = content;
        material.isTitleTile = isTitleTile;
        return material;
    }

    handleTileHover = (tile) => {
        if (!this.state.enteredTheMatrix || !tile || this.isIos) {
            clearTimeout(this.easterEggTimeout);   
            return;
        }

        let tilesData = [].concat(this.state.tilesData);
        tilesData.forEach(i => {
            i.altitude = i === tile ? 0.12 : 0.01;
        });

        this.setState({
            tilesData: tilesData
        });

        if (tile.lat >= -22.5 && tile.lat <= 22.5) {
            this.startEasterEggTimeout(tile);
        }
    }

    startEasterEggTimeout = (tile) => {
        if (this.state.easterEgg) {
            return;
        }

        clearTimeout(this.easterEggTimeout);                                                    
        this.easterEggTimeout = setTimeout(() => {
            this.showEasterEgg(tile);
        }, 2500);
    }

    showEasterEgg = (tile) => {
        switch (tile.easterEgg) {
            case EasterEggs.TourDeFrance:
                this.setState({
                    easterEgg: EasterEggs.TourDeFrance
                }, () => {
                    this.hideEasterEgg(2000);
                });
                break;
            case EasterEggs.CharlieWatts:
                this.setState({
                    easterEgg: EasterEggs.CharlieWatts
                }, () => {
                    this.hideEasterEgg(2000);
                });
                break;
            case EasterEggs.Hooverphonic:
                this.setState({
                    easterEgg: EasterEggs.Hooverphonic
                }, () => {
                    this.hideEasterEgg(4000);
                });
                break;
            case EasterEggs.SquidGame:
                this.setState({
                    easterEgg: EasterEggs.SquidGame
                }, () => {
                    this.hideEasterEgg(5000);
                });
                break;
            case EasterEggs.Covid:
                this.showCovidEaster();
                break;
            default:
                break;
        }
    }

    showCovidEaster = () => {
        let camera = this.world.camera();

        const material = new THREE.SpriteMaterial({
            transparent: true,
            opacity: 0
        });
        const sprite = new THREE.Sprite( material );

        loader.load(covidBackgroundImageUrl, (texture) => {
            material.map = texture;

            const controls = this.world.controls();
            sprite.position.set(controls.target.x, controls.target.y, controls.target.z);

            const scale = getFullSizeScale(camera, sprite, {
                width: texture.image.width,
                height: texture.image.height
            });

            const scaleMax = Math.max(scale[0], scale[1]);
            console.log('scale', scale, scaleMax);

            //let imageAspect = texture.image.height / texture.image.width;

            const scaleFactor = (window.innerWidth > window.innerHeight) ? (window.innerWidth/window.innerHeight) : (window.innerHeight/window.innerWidth);
            scale[0] = scale[0] * scaleFactor;
            scale[1] = scale[1] * scaleFactor;

            // if (window.innerHeight / window.innerWidth > imageAspect) {
            //     scale[1] = (window.innerWidth / window.innerHeight) * imageAspect;
            // } else{
            //     //a2 = (tileHeight / tileWidth) / imageAspect;
            //     scale[0] = (window.innerWidth / window.innerHeight) * imageAspect;
            // }

            sprite.scale.set(scale[0], scale[1], 1);
            this.world.scene().add( sprite );

            let opacityTemp = {
                value: 0
            }
            gsap.to(opacityTemp, {
                value: 1,
                onUpdate: () => {
                    material.opacity = opacityTemp.value
                },
                duration: 1,
                ease: "power1.inOut"
            });

            this.hideEasterEgg(4000, () => {
                let opacityTemp = {
                    value: 1
                }
                gsap.to(opacityTemp, {
                    value: 0,
                    onUpdate: () => {
                        material.opacity = opacityTemp.value
                    },
                    onComplete: () => {
                        this.globeEl.current.scene().remove(sprite);
                    },
                    duration: 1,
                    ease: "power1.inOut"
                });
            });
        });
    }

    hideEasterEgg = (timeout, callback) => {
        setTimeout(() => {
            this.setState({
                easterEgg: null
            });

            if (callback) {
                callback();
            }
        }, timeout);
    }

    handleEasterEggClick = () => {
        let contentItems = this.state.tileSettings.content.filter(i => i.easterEgg === this.state.easterEgg);
        if (!contentItems.length) {
            return;
        }

        this.showTileDetails(contentItems[0]);
    }

    handleSearchResultClicked = (item) => {
        let contentItems = this.state.tileSettings.content.filter(i => i.title === item.title);
        if (contentItems.length) {
            this.showTileDetails(contentItems[0]);
        }
    }

    handleLuckyButtonClick = () => {
        let tile = this.getRandomContentTile();
        this.spinAndShowTile(tile);
    }

    handleToggleLanguage = () => {
        window.sessionStorage.setItem(localeSessionStorageKey, this.state.locale === 'fr-BE' ? 'nl-BE' : 'fr-BE');
        window.location.reload();
    }

    spinAndShowTile(tile) {
        if (tile !== null) {
            this.world.pointOfView({
                lat: tile.lat,
                lng: tile.lng
            }, 1000);

            setTimeout(() => {
                this.handleTileClicked(tile);
            }, 1100);
        }
    }

    handleTileClicked = (tile) => {        
        if (!this.state.enteredTheMatrix) {
            return;
        }

        this.setState({
            materialToggleActive: false
        });

        let pillarContent = tile.material.pillarContent || tile.material.uniforms.pillars[this.state.activePillarIndex];
        this.showTileDetails(pillarContent);
    }

    getRandomContentTile = () => {
        let tile = this.state.tilesData[Math.floor(Math.random() * this.state.tilesData.length)];

        for(let i = 0; i < 10; i++) {
            if (!tile.isPillarTitle && tile.lat !== 82.5 && tile.lat !== -82.5) {
                return tile;
            }

            tile = this.state.tilesData[Math.floor(Math.random() * this.state.tilesData.length)];
        }

        return null;
    }

    showTileDetails(pillarContent) {
        this.setState({
            selectedPillarContent: pillarContent
        }, () => {
            this.world.pauseAnimation();
        });
    }

    handleTileDetailsClose() {
        this.world.resumeAnimation();

        this.setState({
            selectedPillarContent: null
        });
    }

    handleCategoryClick(category) {
        this.world.resumeAnimation();

        let currentTile = this.state.selectedPillarContent.title;

        this.setState({
            selectedPillarContent: null
        });

        let tile = this.getCategoryTile(category, currentTile);
        if (tile) {
            this.spinAndShowTile(tile);
        }
    }

    getCategoryTile = (category, excludeTileTitle) => {
        let tiles = this.state.tilesData.filter(i => i.title !== excludeTileTitle && i.material.pillarContent.categories.filter(c => c.title === category).length);
        let tile = tiles[Math.floor(Math.random() * tiles.length)];
        return tile;
    }

    handleGlobeReady = () => {
        console.log('ready');

        this.world = this.globeEl.current;

        this.world.pointOfView({
            lat: 0,
            lng: -7.5
        });

        if (window.outerWidth >= 768) { // on mobile autorotate is annoying
            this.world.controls().autoRotate = true;
            this.world.controls().autoRotateSpeed = -0.2;
        }

        //this.world.controls().enabled = false;

        //
        //const timeout = this.state.introMessageLoaded ? 0 : 2000;
        setTimeout(() => {
            this.generateTiles();
        }, 2000);

        setTimeout(() => {
            this.setState({
                introMessageLoaded: true
            });
        }, 1000);
    }

    loadGlobeData() {
        contentfulClient.query({
            query: tilesQuery,
            variables: {
                locale: this.state.locale
            }
        }).then(result => {
            this.setState({
                tileSettings: this.mapTilesData(result.data)
            }, () => {
                this.loadTextures();
                this.materials = this.createMaterials();
                this.updateMaterials();

                setTimeout(() => { // wait for scene to be populated (asynchronously)
                    const directionalLight = this.globeEl.current.scene().children.find(obj3d => obj3d.type === 'DirectionalLight');
                    directionalLight && directionalLight.position.set(1, 1, 1); // change light position to see the specularMap's effect
                });

                this.handleGlobeReady();
            });
        });
    }

    componentDidMount() {
        this.loadGlobeData();

        window.addEventListener('resize', () => {
            this.setState({
                globeWidth: window.innerWidth,
                globeHeight: window.innerHeight
            });
        });
    }

    mapTilesData(data) {
        let globeData = data.globeCollection.items[0];

        let globe = {
            title: globeData.title,
            tileDetailsHeader: globeData.tileDetailsHeader,
            tileDetailsFooter: globeData.tileDetailsFooter,
            luckyButtonText: globeData.luckyButtonText,
            languageSwitcherText: globeData.languageSwitcherText,
            searchPlaceholder: globeData.searchPlaceholder,
            searchNoResults: globeData.searchNoResults,
            googleTrendsUrl: globeData.googleTrendsUrl,
            content: globeData.tilesCollection.items.map((i) => {

                let video = null;
                let image = null;
                if (i.video && i.video.contentType.indexOf('video') === 0) {
                    video = i.video;
                } else if (i.image && i.image.contentType.indexOf('video') === 0) {
                    video = i.video;
                } else if (i.image) {
                    image = i.image;
                }

                return {
                    title: i.title,
                    image: image,
                    thumbnail: i.thumbnail,
                    description: i.description,
                    easterEgg: i.easterEgg,
                    categories: i.categoriesCollection.items,
                    embeddedVideo: i.embeddedVideo,
                    mediaSource: i.mediaSource,
                    video: video
                };
            }),

        };

        //console.log('globe', globe);
        return globe;
    }

    getEasterEgg = () => {
        switch (this.state.easterEgg) {
            case EasterEggs.TourDeFrance:
                return <TourDeFrance onClick={this.handleEasterEggClick} />;
            case EasterEggs.CharlieWatts:
                return <CharlieWatts onClick={this.handleEasterEggClick} />;
            case EasterEggs.Hooverphonic:
                return <Hooverphonic onClick={this.handleEasterEggClick} />;
            case EasterEggs.SquidGame:
                return <SquidGame onClick={this.handleEasterEggClick} />;
            default:
                break;
        }

        return null;
    }

    render() {
        return (
            <div>
                <IntroMessage loaded={this.state.introMessageLoaded} title={this.state.tileSettings.title} trendsUrl={this.state.tileSettings.googleTrendsUrl} />
                {this.state.selectedPillarContent &&
                <TileDetails headerTitle={this.state.tileSettings.tileDetailsHeader} footerTitle={this.state.tileSettings.tileDetailsFooter} onCategoryClick={this.handleCategoryClick.bind(this)} imageFormat={this.imageFormat} onClose={this.handleTileDetailsClose.bind(this)} content={this.state.selectedPillarContent}></TileDetails>
                }
                <LanguageSwitcher currentLocale={this.state.locale} onClick={this.handleToggleLanguage} title={this.state.tileSettings.languageSwitcherText} />
                <SearchBox noResults={this.state.tileSettings.searchNoResults} placeholder={this.state.tileSettings.searchPlaceholder} results={this.state.searchResults} onItemClick={this.handleSearchResultClicked} />
                <div className="lewis-globe-container">
                    <Globe
                        ref={this.globeEl}
                        width={this.state.globeWidth}
                        height={this.state.globeHeight}
                        backgroundImageUrl={this.state.backgroundImageUrl}
                        tilesData={this.state.tilesData}
                        tileMaterial="material"
                        waitForGlobeReady={true}
                        tileWidth="width"
                        tileHeight="height"
                        tileAltitude="altitude"
                        showAtmosphere={false}
                        rendererConfig={{ antialias: !this.isIos, alpha: false, powerPreference: 'high-performance' }}
                        onTileHover={this.handleTileHover}
                        onTileClick={this.handleTileClicked}
                    />
                </div>
                <div className="footer-buttons-container">
                <LuckyButton title={this.state.tileSettings.luckyButtonText} onClick={this.handleLuckyButtonClick} />
                </div>
                {this.getEasterEgg()}
            </div>

        );
    }
}

export default LewisGlobe;