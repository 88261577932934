import React, { Component } from 'react';
import { KeyCodes } from './KeyCodes';
import './SearchBox.scss';

const SearchBoxMode = {
    closed: 0,
    open: 1,
}
class SearchBox extends Component {
    constructor(props) {
        super(props);

        this.autoCompleteInputRef = React.createRef();
        this.autocompletListBoxRef = React.createRef();

        this.state = {
            mode: SearchBoxMode.closed,
            filteredResults: [],
            selectedIndex: -1,
            searchValue: ''
        }
    }

    handleOpen = () => {
        this.setState({
            mode: SearchBoxMode.open
        });
    }

    handleClose = (e) => {
        e.stopPropagation();
        this.setState({
            mode: SearchBoxMode.closed
        });
    }

    handleItemClick = (item) => {
        this.props.onItemClick(item);

        this.setState({
            mode: SearchBoxMode.closed
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // this is fine for our scenario where the items in the results aren't updated, they are all loaded at the beginning
        if (prevProps.results.length !== this.props.results.length) {
            this.setState({
                filteredResults: this.props.results
            });
        }
    }

    handleHeaderClick = () => {
        this.autoCompleteInputRef.current.focus();
    }

    handleInputKeyDown = (e) => {
        switch (e.keyCode) {
            case KeyCodes.Up:
                if (this.state.selectedIndex > -1) {
                    this.updateSelectedIndex(this.state.selectedIndex-1);
                } else if (this.state.selectedIndex === -1) {
                    this.updateSelectedIndex(this.state.filteredResults.length - 1);
                }
                break;
            case KeyCodes.Down:
                if (this.state.mode === SearchBoxMode.closed) {
                    this.setState({
                        mode: SearchBoxMode.open
                    });
                    break;
                }

                if (this.state.selectedIndex < (this.state.filteredResults.length-1)) {
                    this.updateSelectedIndex(this.state.selectedIndex+1);
                }
                break;
            case KeyCodes.Enter:
                if (this.state.selectedIndex > -1) {
                    this.props.onItemClick(this.state.filteredResults[this.state.selectedIndex]);
                    this.setState({
                        mode: SearchBoxMode.closed,
                        selectedIndex: -1,
                        searchValue: ''
                    });
                }
                break;
            case KeyCodes.Escape:
                this.setState({
                    mode: SearchBoxMode.closed
                });
                break;
            default:
                break;
        }
    }

    handleInputChange = (e) => {
        this.setState({
            searchValue: e.target.value
        });
        
        if (!e.target.value) {
            this.setState({
                filteredResults: this.props.results
            });
            return;
        }

        let valueLowerCase = e.target.value.toLowerCase();                
        let filteredResults = this.props.results.filter(i => i.title.toLowerCase().indexOf(valueLowerCase) > -1);
        this.setState({
            selectedIndex: -1,
            filteredResults: filteredResults
        });
    }

    updateSelectedIndex = (index) => {
        this.setState({
            selectedIndex: index
        }, () => {
            let selectedElem = this.autocompletListBoxRef.current.querySelector('.search-box__result-item[aria-selected="true"]');
            if (selectedElem) {
                selectedElem.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
            }
        });
    }

    handleInputFocus = (e, value) => {
        this.handleOpen();
    }

    render() {
        const resultItems = this.state.filteredResults.map((item, index) => {
            const selected = index === this.state.selectedIndex;
            return <li className="search-box__result-item" data-option-value={index} tabIndex="-1" aria-disabled="false" aria-selected={selected} role="option" onClick={() => this.handleItemClick(item)} key={item.title}><span>{item.title}</span></li>
        });

        const wrapperClassName = this.state.mode === SearchBoxMode.open ? 'search-box search-box--open' : 'search-box';

        let autocompleteClassName = "search-box__results" + (this.state.mode === SearchBoxMode.open ? ' search-box__results--open': '');
        if (this.state.filteredResults.length === 0) {
            autocompleteClassName += ' search-box__results--no-results';
        }

        return (
            <div className={wrapperClassName}>
                <div className="search-box__header" onClick={this.handleHeaderClick}>
                    <svg className="search-box__logo" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_1106_3686)">
                            <path d="M6.76032 18.0255L5.75088 21.7939L2.06136 21.872C0.958739 19.8269 0.333313 17.487 0.333313 15.0004C0.333313 12.596 0.918071 10.3286 1.95459 8.33203H1.95539L5.24009 8.93423L6.67899 12.1992C6.37783 13.0772 6.21369 14.0197 6.21369 15.0004C6.2138 16.0648 6.4066 17.0847 6.76032 18.0255Z" fill="#FBBB00" />
                            <path d="M29.08 12.291C29.2465 13.1682 29.3333 14.074 29.3333 14.9998C29.3333 16.0379 29.2242 17.0505 29.0162 18.0273C28.3104 21.3511 26.466 24.2535 23.911 26.3074L23.9102 26.3066L19.773 26.0955L19.1874 22.4402C20.8828 21.446 22.2077 19.89 22.9056 18.0273H15.1521V12.291H23.0187H29.08Z" fill="#518EF8" />
                            <path d="M23.9101 26.3071L23.9109 26.3079C21.426 28.3052 18.2695 29.5003 14.8333 29.5003C9.31134 29.5003 4.51042 26.4139 2.06134 21.8719L6.7603 18.0254C7.98482 21.2934 11.1374 23.6198 14.8333 23.6198C16.4219 23.6198 17.9102 23.1904 19.1873 22.4407L23.9101 26.3071Z" fill="#28B446" />
                            <path d="M24.0887 3.83817L19.3914 7.68384C18.0697 6.85768 16.5073 6.38043 14.8334 6.38043C11.0539 6.38043 7.84234 8.81354 6.67916 12.1988L1.9555 8.33159H1.95471C4.36794 3.67884 9.22941 0.5 14.8334 0.5C18.3517 0.5 21.5775 1.75323 24.0887 3.83817Z" fill="#F14336" />
                        </g>
                        <defs>
                            <clipPath id="clip0_1106_3686">
                                <rect width="29" height="29" fill="white" transform="translate(0.333313 0.5)" />
                            </clipPath>
                        </defs>
                    </svg>
                    <div className="search-box__input">
                        <input placeholder={this.props.placeholder} ref={this.autoCompleteInputRef} aria-owns="autocomplete-options-listbox" onKeyDown={this.handleInputKeyDown} value={this.state.searchValue} onChange={this.handleInputChange} onFocus={this.handleInputFocus} autoCapitalize="none" type="text" autoComplete="off" aria-autocomplete="list" role="combobox" aria-controls="autocomplete-options-listbox" aria-expanded={this.state.mode === SearchBoxMode.open} />
                    </div>

                    {this.state.mode === SearchBoxMode.closed &&
                    <button onClick={this.handleOpen} className="search-box__action search-box__action--open" aria-label="Open">
                        <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.6591 -8.95061e-07L15 1.37275L7.5 9L-3.33735e-07 1.36504L1.34091 -3.56616e-07L7.5 6.26992L13.6591 -8.95061e-07Z" fill="#4285F4"/>
                        </svg>
                    </button>
                    }
                    {this.state.mode === SearchBoxMode.open &&
                    <button onClick={this.handleClose} className="search-box__action search-box__action--close" aria-label="Close">
                        <svg width="26" height="30" viewBox="0 0 26 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.3125 7.05176L5.6875 23.0952" stroke="black" strokeWidth="1.625" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M20.3125 23.0952L5.6875 7.05176" stroke="black" strokeWidth="1.625" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button>
                    }
                </div>
                <div ref={this.autocompletListBoxRef} className={autocompleteClassName}>
                    <ol id="autocomplete-options-listbox" role="listbox">
                        {resultItems}
                        {this.state.filteredResults.length === 0 &&
                        <li className="search-box__result-item search-box__result-item--disabled" tabIndex="-1" aria-disabled="true" aria-selected="false" role="option"><span>{this.props.noResults}</span></li>
                        }
                    </ol>
                </div>
            </div>
        )
    }
}

export default SearchBox;