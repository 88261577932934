import React, { Component, Fragment } from 'react';
import './IntroMessage.scss';
class IntroMessage extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            logoState: 'visible'
        };
    }

    logoLoaded = () => {
        setTimeout(() => {
            this.setState({
                logoState: 'hiding'
            }, () => {
                setTimeout(() => {
                    this.setState({
                        logoState: 'hidden'
                    });
                }, 2000);
            });
        }, 2000);
    }

    render() {
        const className = this.props.loaded ? 'intro-message intro-message--loaded' : 'intro-message';
        const logoClassName = 'intro-message__logo intro-message__logo--' + this.state.logoState;

        return (
            <Fragment>
                <a target="_blank" rel="noreferrer" href={this.props.trendsUrl} className={className}>
                    {this.props.title}
                </a>
                {this.state.logoState !== 'hidden' &&
                    <div className={logoClassName}>
                        <img onLoad={this.logoLoaded} alt="Google Logo" src={process.env.PUBLIC_URL + "/assets/images/google.gif"} />
                    </div>
                }
            </Fragment>
        );
    }
}

export default IntroMessage;