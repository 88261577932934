import React, { Component } from 'react';
import './Hooverphonic.scss';

class Hooverphonic extends Component {
    render() {
        return (
            <img onClick={this.props.onClick} className="easter-egg--hooverphonic" src={process.env.PUBLIC_URL + "/assets/images/easter/hooverphonic/hooverphonic2.jpg"} alt="Hooverphonic" />
        );
    }
}

export default Hooverphonic;