import React, { Component } from 'react';
import './SquidGame.scss';

class SquidGame extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            activeItem: ''
        };
    }

    handleTriangleLoaded = () => {
        setTimeout(() => {
            this.setState({
                activeItem: 'circle'
            })
        }, 100);
        setTimeout(() => {
            this.setState({
                activeItem: 'triangle'
            })
        }, 1500);
        setTimeout(() => {
            this.setState({
                activeItem: 'square'
            })
        }, 3000);
        setTimeout(() => {
            this.setState({
                activeItem: ''
            }, () => {
                setTimeout(() => {
                    this.setState({
                        activeItem: 'none'
                    });
                }, 500);
            });
        }, 4000);
    }

    render() {
        if (this.state.activeItem === 'none') {
            return null;
        }
        return (
            <div onClick={this.props.onClick} className={"easter-egg--squid-game easter-egg--squid-game-active-" + this.state.activeItem}>
                <img onLoad={this.handleTriangleLoaded} className="easter-egg__squid-game-triangle" src={process.env.PUBLIC_URL + "/assets/images/easter/squid-game/triangle.svg"} alt="Squid Game Triangle" />
                <img className="easter-egg__squid-game-circle" src={process.env.PUBLIC_URL + "/assets/images/easter/squid-game/circle.svg"} alt="Squid Game Circle" />
                <img className="easter-egg__squid-game-square" src={process.env.PUBLIC_URL + "/assets/images/easter/squid-game/square.svg"} alt="Squid Game Square" />
            </div>
        );
    }
}

export default SquidGame;