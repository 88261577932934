import React, { Component } from 'react';
import './TileDetails.scss';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { getSrcSet } from '../common/scripts/utils';

class TileDetails extends Component {
    constructor(props) {
        super(props);

        this.modalRef = React.createRef();
    }

    handleBackgroundClick = (e) => {
        if (e.target === this.modalRef.current) {
            this.props.onClose();
        }
    }

    handleCloseClick = (e) => {
        this.props.onClose();
    }

    render() {
        const articleContent = {
            __html: documentToHtmlString(this.props.content.description.json)
        };

        const video = this.props.content.video;
        const image = this.props.content.image;
        const imageSrcSet = image ? getSrcSet(image.url, [320, 480, 640, 1200], this.props.imageFormat) : null;

        const categories = this.props.content.categories.map(i => {
            return (
                <button key={i.title} className="btn" onClick={() => this.props.onCategoryClick(i.title)}>{i.title}</button>
            );
        });

        const embedContent = {
            __html: this.props.content.embeddedVideo
        };

        return (
            <aside className="modal tile-details" ref={this.modalRef} aria-modal="true" role="dialog" onClick={this.handleBackgroundClick}>
                <div className="modal__dialog">
                    <div className="modal__content">
                        <header>
                            {this.props.headerTitle}
                            <button onClick={this.handleCloseClick} className="btn modal__close" aria-label="Close"></button>
                        </header>
                        <article className="modal__body tile-details__body">
                            <h3>{this.props.content.title}</h3>
                            {this.props.content.subTitle &&
                            <h5>{this.props.content.subTitle}</h5>
                            }
                            {video && 
                            <video muted autoPlay loop className="tile-details__video" src={video.url} controls />
                            }
                            {image &&
                            <img sizes="(max-width: 800px) 100vw, 800px" className="tile-details__hero-image" alt={image.alt || this.props.content.title} src={image.url + (this.props.imageFormat ? '?fm=' + this.props.imageFormat : '')} srcSet={imageSrcSet} />
                            }
                            {this.props.content.embeddedVideo &&
                            <div className="embed-container" dangerouslySetInnerHTML={embedContent}></div>
                            }
                            {this.props.content.mediaSource &&
                            <p className="tile-details__media-source">{this.props.content.mediaSource}</p>
                            }
                            <div className="tile-details__content" dangerouslySetInnerHTML={articleContent}></div>
                        </article>
                        <footer className={this.props.content.categories.length === 0 ? 'no-border' : ''}>
                            {this.props.content.categories.length > 0 &&
                            <p className="tile-details__footer-instruction">{this.props.footerTitle}</p>
                            }
                            <div className="tile-details__footer-buttons">
                                {categories}
                            </div>
                        </footer>
                    </div>
                </div>
            </aside>
        );
    }
}

export default TileDetails;