import React, { Component } from 'react';
import './TourDeFrance.scss';

class TourDeFrance extends Component {
    render() {
        return (
            <img onClick={this.props.onClick} className="easter-egg--tour-de-france" src={process.env.PUBLIC_URL + "/assets/images/easter/TourDeFrance.gif"} alt="Tour de France Easter Egg" />
        );
    }
}

export default TourDeFrance;